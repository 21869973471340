<template>
  <div class="UploadFile"
    @mouseover="hoverPendingFiles"
    @mouseleave="endHoverPendingFiles"
  >
    <slot name="activator" v-bind:activatorProps="activatorProps">
      <v-btn color="primary" text @contextmenu.prevent="rightClick" @click="leftClick">
        <v-icon v-if="!showText">{{ activatorCaption }}</v-icon>
        <div v-else>{{ activatorCaption }}</div>
      </v-btn>
    </slot>
    <v-dialog v-model="uploadDialog" width="80vw" max-height="80vh">
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-container>
                <v-row>
                  <v-col>
                    <h3 :title="titleTooltip">File attachments</h3>
                  </v-col>
                </v-row>
                <span>Manually upload an attachment through the web</span>
                <v-row>
                  <v-col>
                    <v-file-input id="tmcUploadFileImportFile"
                      class="tmc-uploadfile-importfile"
                      accept=".pdf,.jpg,.png,.tiff"
                      label="Import (.pdf,.jpg,.png,.tiff)"
                      show-size
                      clearable
                      @change="doUpload"
                      :key="afterUploadClearKey"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-data-table
                      :headers="headers"
                      :footer-props="footerProps"
                      :items="files"
                      :items-per-page="10"
                      dense
                      class="elevation-1"
                      @click:row="previewFile"
                    >
                      <template v-slot:[`item`]="{ item }">
                        <tr>
                          <td class="text-start clickable" @click="previewFile(item)">
                            {{ item.filename }}
                          </td>
                          <td class="text-start clickable" @click="previewFile(item)">
                            {{ item.lengthStr }}
                          </td>
                          <td class="text-start">
                            <v-icon small class="mr-2 tmc-uploadfile-download-icon" tabindex="-1"
                            @click="download(item)">
                              {{ icons.mdiDownload }}
                            </v-icon>
                            <v-icon small class="mr-2 tmc-uploadfile-delete-icon" tabindex="-1"
                            @click="removeOne(item)">
                              {{ icons.mdiDelete }}
                            </v-icon>
                            <v-icon
                              v-if="mimeType(item).indexOf('image/') > -1"
                              small
                              class="mr-2"
                              tabindex="-1"
                              @click="rotateLft(item)"
                            >
                              {{ icons.mdiRotateLeft }}
                            </v-icon>
                            <v-icon
                              v-if="mimeType(item).indexOf('image/') > -1"
                              small
                              class="mr-2"
                              tabindex="-1"
                              @click="rotateRt(item)"
                            >
                              {{ icons.mdiRotateRight }}
                            </v-icon>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="6">
              <v-container>
                <v-row>
                  <v-col>
                    <PreviewFile
                      :src="previewSrc"
                      :file="activeFile"
                      :isPreviewSupported="isPreviewSupported"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="uploading">
              <span>{{ statusText }}</span>
            </v-col>
            <v-spacer />
            <v-col id="tmcUploadFile"
            align="right">
              <v-btn class="tmc-upload-file-close-btn"
                icon @click="uploadDialog = false">
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-data-table v-if="showPending"
      class="v-data-table-pending-files elevation-4"
      dense
      :items="pendingFiles"
      :headers="pendingFilesHeaders"
      :footer-props="footerProps"
    >
      <template v-slot:footer>
      </template>
      <template v-slot:[`footer.page-text`]>
        <v-toolbar flat>
          <v-btn
            color="primary"
            class="mb-2 tmc-clear-pending-files-btn"
            @click="confirmClearPendingFiles"
          >
            Clear All
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  mapGetters, mapActions, mapState, mapMutations,
} from 'vuex';
import {
  mdiClose,
  mdiDelete,
  mdiDownload,
  mdiRotateLeft,
  mdiRotateRight,
  mdiAttachment,
} from '@mdi/js';
import WhiteImageSmall from '@/assets/WhiteImageSmall.png';
// import FormData from 'form-data';
import { clients } from '../../util/clients';
import UploadFileMixin from './UploadFile.mixin';
import PreviewFile from './PreviewFile.vue';
import { mimeTypeByFileName, sanitizeFilename } from '../../util/shared/vue-global';

const { backendRest } = clients.direct;

const uniqueTempIdForUser = (that) => {
  const timeStr = new Date().getTime();
  const userId = that.authUser;
  return `${userId}${timeStr}`;
};

const initdata = () => ({
  icons: {
    mdiClose,
    mdiDelete,
    mdiDownload,
    mdiRotateLeft,
    mdiRotateRight,
    mdiAttachment,
  },
  headers: [
    { text: 'File name', value: 'filename' },
    { text: 'Size', value: 'lengthStr' },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
    },
  ],
  pendingFilesHeaders: [
    { text: 'File name', value: 'filename' },
    { text: 'Size', value: 'lengthStr' },
  ],
  afterUploadClearKey: 0,
  uploadDialog: false,
  showPending: false,
  file: undefined,
  timing: undefined,
  uploading: false,
  statusText: '',
  previewSrc: '',
  activeFile: undefined,
  previewSupport: ['image/png', 'image/jpeg', 'application/pdf', 'image/tiff'],
  isPreviewSupported: true,
});

const calcRotation = (file) => {
  const rotate = file && file.metadata ? file.metadata.rotate || 0 : 0;
  return rotate % 4;
};

export default {
  name: 'UploadFile',
  components: {
    PreviewFile,
  },
  mixins: [UploadFileMixin],
  data: () => initdata(),
  watch: {
    uploadDialog(visible) {
      if (visible) {
        // Dialog was opened
        this.onOpened();
      }
    },
  },

  props: {
    bucketName: {
      type: String,
      required: true,
    },
    program: {
      type: String,
      required: true,
    },
    parentObjectType: {
      type: String,
      required: true,
    },
    parentObjectId: {
      type: String,
      required: true,
    },
    itemIsSaved: {
      type: Boolean,
      required: true,
    },
    showText: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      files: (state) => state.files.files,
      pendingFiles: (state) => state.files.pendingFiles,
    }),
    ...mapGetters('auth/token', ['authUser']),
    ...mapGetters('files', ['fileCount']),
    activatorCaption() {
      if (this.showText) {
        return `Upload File (${this.fileCount})`;
      }
      return this.icons.mdiAttachment;
    },
    titleTooltip() {
      return `(for: ${this.bucketName} - ${this.parentObjectType}:${this.parentObjectId})`;
    },
    activatorProps() {
      return {
        caption: this.activatorCaption,
        leftClick: this.leftClick,
        rightClick: this.rightClick,
      };
    },
    footerProps() {
      return {
        'show-current-page': true,
        'page-text': '',
        'disable-items-per-page': true,
      };
    },
    metadata() {
      const args = {
        bucketName: this.bucketName,
        program: this.program,
        parentObjectType: this.parentObjectType,
        parentObjectId: this.parentObjectId,
      };
      return args;
    },
  },
  methods: {
    ...mapActions('files', [
      'backgroundAttachFiles',
      'loadAttachedFiles',
      'removeAttachedFile',
      'rotateImage',
      'loadPendingFiles',
      'clearPendingFiles',
    ]),
    ...mapMutations('files', ['setFiles']),
    ...mapActions(['flashInfo', 'flashWarn', 'flashError', 'reAuth']),
    async hoverPendingFiles() {
      if (this.showText) {
        await this.loadPendingFiles();
        this.showPending = true;
      }
    },
    setPendingFalse() {
      this.showPending = false;
    },
    endHoverPendingFiles() {
      if (this.showText) {
        setTimeout(this.setPendingFalse, 3700);
      }
    },
    confirmClearPendingFiles() {
      if (window.confirm('Are you sure?')) {
        this.clearPendingFiles();
        this.flashInfo('Cleared files pending / awaiting attachment!');
      }
    },
    mimeType(item) {
      const filename = item ? item.filename : '';
      return mimeTypeByFileName(filename);
    },
    async onOpened() {
      this.previewSrc = WhiteImageSmall;
      await this.loadAttachedFiles(this.metadata);
      if (this.files.length > 0) {
        this.previewFile(this.files[0]);
      }
    },
    async download(file) {
      const rotate = calcRotation(file);
      const url = await this.generateDowloadUrl(file, rotate);
      window.open(url, '_blank');
    },
    async previewFile(file) {
      const rotate = calcRotation(file);
      if (this.previewSupport.includes(this.mimeType(file))) {
        this.previewSrc = await this.generateDowloadUrl(file, rotate, 'preview');
        this.isPreviewSupported = true;
        this.activeFile = file;
      } else {
        this.previewSrc = WhiteImageSmall;
        this.activeFile = undefined;
        this.isPreviewSupported = false;
      }
    },
    async generateDowloadUrl(file, rotate, fetchType = 'download') {
      const { jwt } = await this.reAuth();
      const url = `${backendRest.defaults.baseURL}/doc/download/${this.program}/${file.id}?rotate=${rotate}&token=${jwt}&fetchType=${fetchType}`;
      return url;
    },
    async removeOne(file) {
      if (window.confirm('Are you sure you want to delete this file?')) {
        const { id, bucketName } = file;
        try {
          await this.removeAttachedFile({
            id,
            bucketName,
          // program: this.program,
          // parentObjectType: this.parentObjectType,
          // parentObjectId: this.parentObjectId,
          });
        } catch (error) {
          if (error.message !== '403 Forbidden') {
            throw error;
          }
        }
        this.onOpened();
        this.fileDeletePaperClipUpdate();
      }
    },
    rotateLft(file) {
      this.rotate(file, -1);
    },
    rotateRt(file) {
      this.rotate(file, 1);
    },
    rotate(file, howMuch) {
      const { id, metadata } = file;
      const sum = (metadata.rotate || 0) + howMuch;
      // don't let it inc/dec forever
      if (sum % 4 === 0) {
        metadata.rotate = 0;
      } else {
        metadata.rotate = sum;
      }
      this.rotateImage({
        id,
        rotate: metadata.rotate,
        bucketName: this.bucketName,
      });
      this.previewFile(file);
    },
    async doUpload(file) {
      this.file = file;
      if (file && file.size && file.name) {
        const { program, parentObjectType, parentObjectId } = this;

        this.timing = new Date();
        this.uploading = true;
        this.statusText = 'Loading file...';

        const metadataStr = JSON.stringify({
          rotate: 0,
          program,
          parentObjectType,
          parentObjectId,
        });

        const uniqueTempId = uniqueTempIdForUser(this);
        const form = new FormData();
        form.append('id', uniqueTempId);
        form.append('file', sanitizeFilename(file.name));
        form.append('bucket', this.bucketName);
        form.append('metadata', metadataStr);
        form.append('data', file);

        const formHeaders = {
          'Content-Type': 'multipart/form-data',
        };
        let results = null;
        try {
          results = await backendRest.post(`/doc/upload/${uniqueTempId}`, form, {
            headers: formHeaders,
          });
          if (!results || !results.data || results.data.status !== 'success') {
            this.flashError('Unable to upload file.');
            this.statusText = '';
          } else {
            const elapsed = new Date().getTime() - this.timing.getTime();
            this.statusText = `Done. Time elapsed: ${elapsed / 1000}`;
            this.file = undefined;
            this.afterUploadClearKey += 1;

            this.flashInfo('File uploaded!');
            await this.loadAttachedFiles(this.metadata);
          }
        } catch (error) {
          this.file = undefined;
          if (error.response.status !== 403) {
            throw error;
          }
        }
        this.uploading = false;
        this.fileUploadPaperClipUpdate();
      }
    },
    async rightClick() {
      if (this.itemIsSaved) {
        this.flashInfo('Background attaching scanned images...');
        await this.backgroundAttachFiles(this.metadata);
        await this.loadAttachedFiles(this.metadata);
        this.fileUploadPaperClipUpdate();
      } else {
        // a somewhat sane default behavior, for the default activator button
        this.flashWarn('You cannot attach images until your record is saved.');
        this.$emit('pre:save');
      }
    },
    leftClick() {
      this.loadAttachedFiles(this.metadata);
      if (this.itemIsSaved) {
        this.uploadDialog = true;
      } else {
        // a somewhat sane default behavior, for the default activator button
        this.flashWarn('You cannot attach images until your record is saved.');
        this.$emit('pre:save');
      }
    },
  },
};
</script>

<style lang="sass">
td.clickable:hover
  cursor: pointer
  font-weight: 600
.v-data-table-pending-files
  position: absolute
  bottom: 10vh
  right: 5vw
</style>

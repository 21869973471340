<template>
  <tr :class="canEdit ? 'text-start clickable' : 'text-start'"
    @click="canEdit && emitEditItem(item)"
  >
    <td v-if="showSelect" @click.stop>
      <v-checkbox v-model="selectedTask" :value="item"
        style="margin:0px;padding:0px" hide-details />
    </td>
    <td
      v-for="header in headerList" :key="header.value"
      :class="determineCssClasses(item, canEdit, header)"
    >
      <span v-if="header.formatter === 'file'"
        @click.stop
      >
        <UploadFile
          v-if=item.hasFiles
          :bucketName="uploadFileParams.bucketName"
          :program="uploadFileParams.program"
          :parentObjectType="uploadFileParams.parentObjectType"
          :parentObjectId="item._id"
          :itemIsSaved="true"
          :showText="false"
        ></UploadFile>
      </span>
      <span v-else-if="header.formatter === 'localDateTime'">
        {{toLocalDateTime(resolveSubProp(header.value, item))}}
      </span>
      <span v-else-if="header.formatter === 'shortDate'">
        {{toShortDate(resolveSubProp(header.value, item))}}
      </span>
      <span v-else-if="header.formatter === 'amount'">
        {{amountFormat(resolveSubProp(header.value, item))}}
      </span>
      <span v-else-if="header.formatter === 'auto'">
        {{autoDetectFormat(resolveSubProp(header.value, item))}}
      </span>
      <span v-else-if="header.formatter === 'getFirstChar'">
        {{getFirstChar(resolveSubProp(header.value, item))}}
      </span>
      <span v-else-if="header.formatter === 'boolToStr'">
        {{boolToStr(resolveSubProp(header.value, item))}}
      </span>
      <span v-else-if="header.formatter === 'customMethod'">
        {{header.methodDefinition(resolveSubProp(header.value, item))}}
      </span>
      <span v-else>
        {{resolveSubProp(header.value, item)}}
      </span>
    </td>
    <td v-if="canDelete" @click.stop>
      <v-icon
        icon
        small class="mr-2"
        @click="deleteRowItem(item)"
      >
        {{icons.mdiDelete}}
      </v-icon>
    </td>
    <td v-if="showExpand" @click.stop>
      <v-btn
        icon
        color="blue"
        @click="toggleExpandableRowItem(item)"
      >
        <v-icon>{{ toggleExpandableIcon }}</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import {
  mdiPencil,
  mdiDelete,
  mdiChevronDown,
  mdiChevronUp,
} from '@mdi/js';
import {
  amountFormat,
  toLocalDateTime,
  resolveSubProp,
} from '../../../../util/shared/vue-global';
import {
  toShortDate,
  getFirstChar,
  boolToStr,
} from '../../../../util/shared/tmc-global';

import UploadFile from '../../UploadFile.vue';
// please don't use this widely throughout the project. It's
// specific logic for a specific screen, but I am exporting it
// for unit testing purposes.
export const autoDetectFormat = (valueStr = '') => {
  const value = (valueStr != null) ? valueStr.toString() : '';
  // console.info(`auto formatting: ${value}`);
  if (value.match(/Coordinated Universal Time/)) {
    return new Date(valueStr).toLocaleString();
  }
  if (value.match(/[0-9.,$]/) && value.match(/[.,$]/) && !value.match(/[a-zA-Z]/)) {
    const strIsCurrency = value.match(/[$]/) ? '$' : '';
    const bareAmount = value.replace(/[,$]*/g, '');
    return `${strIsCurrency}${amountFormat(bareAmount, true)}`;
  }
  return value;
};

export default {
  name: 'CRUDRow',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array,
      default: () => ([]),
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    uploadFileParams: {
      type: Object,
      default: () => ({}),
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => ([]),
    },
    expanded: {
      type: Array,
      default: () => ([]),
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UploadFile,
  },
  data: () => ({
    icons: {
      mdiPencil,
      mdiDelete,
      mdiChevronDown,
      mdiChevronUp,
    },
  }),
  computed: {
    headerList() {
      if (this.showSelect) {
        return this.headers.slice(1);
      }
      return this.headers;
    },
    selectedTask: {
      get() {
        return this.selectedItems || [];
      },
      set(value) {
        this.$emit('emitSelectedItem', value);
      },
    },
    toggleExpandableIcon() {
      return (this.expanded || []).indexOf(this.item)
        ? this.icons.mdiChevronDown : this.icons.mdiChevronUp;
    },
  },
  methods: {
    resolveSubProp,
    amountFormat,
    toLocalDateTime,
    toShortDate,
    autoDetectFormat,
    getFirstChar,
    boolToStr,
    emitEditItem(item) {
      this.$emit('editItem', item);
    },
    determineCssClasses(item, canEdit, header) {
      const classes = [];
      if (item && item.crudRowCssAppend && Array.isArray(item.crudRowCssAppend)) {
        classes.push(...item.crudRowCssAppend);
      }
      if (canEdit) {
        classes.push('clickable');
      }
      if (header && header.align === 'right') {
        classes.push('text-align-right');
        classes.push('pointer');
      }
      return classes;
    },
    toggleExpandableRowItem(item) {
      this.$emit('toggleExpandableRowItem', item);
    },
    deleteRowItem(item) {
      this.$emit('emitDeleteRowItem', item);
    },
  },
};
</script>

<style lang="sass">
.text-start .clickable:hover
  cursor: pointer
  font-weight: 600
.text-align-right
  text-align: right
.department-list-dropdown
  margin-top: 1.8vw
</style>

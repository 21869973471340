<template>
  <div class="scrollableDiv">
    <v-img
      v-if="isImageFile || !isPreviewSupported"
      contain
      height="70vh"
      :src="src"
      :gradient="previewGradient"
      class="elevation-10 ma-2"
    >
      <v-col align="center" class="mt-8">
        {{previewText}}
      </v-col>
    </v-img>
    <pdf
      v-if="isPdfFile && forceUpdate"
      :src="src"
    ></pdf>
  </div>
</template>

<script>
import pdf from 'pdfvuer';
import {
  mimeTypeByFileName,
} from '../../util/shared/vue-global';

export default {
  name: 'PreviewFile',
  components: {
    pdf,
  },
  props: {
    src: {
      type: String,
      default: () => (''),
    },
    file: {
      type: Object,
      default: () => ({}),
    },
    isPreviewSupported: {
      type: Boolean,
      default: () => (true),
    },
  },
  data: () => ({
    previewText: '',
    forceUpdate: true,
    previewGradient: '',
  }),
  computed: {
    mimeType() {
      const filename = this.file ? this.file.filename : '';
      return mimeTypeByFileName(filename);
    },
    isPdfFile() {
      return this.mimeType.indexOf('/pdf') > -1;
    },
    isImageFile() {
      return this.mimeType.indexOf('image/') > -1;
    },
  },
  watch: {
    src() {
      // on changing src it is not loading new pdf file
      // so force reloading pdfvuer component
      if (this.isPdfFile) {
        this.forceUpdate = false;
        setTimeout(() => {
          this.forceUpdate = true;
          return this.forceUpdate;
        }, 1);
      }
    },
    isPreviewSupported() {
      if (!this.isPreviewSupported) {
        this.previewGradient = 'to top right, rgba(130,130,140,.7), rgba(80,80,90,.7)';
        this.previewText = 'File\'s preview is not supported';
      } else {
        this.previewGradient = '';
        this.previewText = '';
      }
    },
  },
};
</script>

<style lang="sass">
.scrollableDiv
  overflow-y: scroll
  height: 60vh
</style>

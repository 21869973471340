import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations('OD', ['updateOdLocalFileStatus']),
    ...mapMutations('war/transaction', ['updateWarLocalFileStatus']),
    ...mapMutations('misc/miscTransaction', ['updateMiscLocalFileStatus']),
    ...mapMutations('mtg/mtgTransaction', ['updateMtgLocalFileStatus']),
    fileUploadPaperClipUpdate() {
      if (this.fileCount === 1) {
        switch (this.parentObjectType) {
          case 'ODTransaction':
            this.updateOdLocalFileStatus({ objectId: this.parentObjectId, status: true });
            break;
          case 'warTransaction':
            this.updateWarLocalFileStatus({ objectId: this.parentObjectId, status: true });
            break;
          case 'miscTransaction':
            this.updateMiscLocalFileStatus({ objectId: this.parentObjectId, status: true });
            break;
          case 'mtgTransaction':
            this.updateMtgLocalFileStatus({ objectId: this.parentObjectId, status: true });
            break;
          default:
            break;
        }
      }
    },
    fileDeletePaperClipUpdate() {
      if (this.fileCount === 0) {
        switch (this.parentObjectType) {
          case 'ODTransaction':
            this.updateOdLocalFileStatus({ objectId: this.parentObjectId, status: false });
            break;
          case 'warTransaction':
            this.updateWarLocalFileStatus({ objectId: this.parentObjectId, status: false });
            break;
          case 'miscTransaction':
            this.updateMiscLocalFileStatus({ objectId: this.parentObjectId, status: false });
            break;
          case 'mtgTransaction':
            this.updateMtgLocalFileStatus({ objectId: this.parentObjectId, status: false });
            break;
          default:
            break;
        }
      }
    },
  },
};
